import React from "react"
import ecommerceIMG from "../../../components/assets/img/pages/landing/new/ecommerce.png"

const Ecommerce = () => {
    return (
        <section className="ecommerce" id="ecommerce">
            <img src={ecommerceIMG} alt="e-commerce" />
            <div className="flexbox">
                <span className="title blue-font">
                    O Gifthy vai apresentar seu
                    <br /> e-commerce a uma nova rede social.
                </span>
                <span className="text blue-font">
                    Seus produtos chegando a um grupo seleto de pessoas
                    <br /> que valorizam presentear.
                </span>
            </div>
        </section>
    )
}

export default Ecommerce
